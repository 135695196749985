<!--
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2022-05-28 09:59:27
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\views\county.vue
-->
<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="main">
      <top-header></top-header>
      <div class="content">
        <div class="left">
          <title2 name="农资/农技概况" style="margin: 0px 0 24px"></title2>
          <div class="liBox">
            <div class="liCon">
              <div class="c">种子种苗:<span>{{ leftData.zzzm }}元</span></div>
              <div class="c">农资保障:<span>{{ leftData.nzbz }}元</span></div>
            </div>
            <div class="liCon">
              <div class="c">技术指导:<span>{{ leftData.jszd }}小时</span></div>
              <div class="c">气象服务:<span>{{ leftData.qxfw }}次</span></div>
            </div>
          </div>
          <!-- <div class="changePie">
            <span class="text" @click="init(1)">农技分类</span>
            <div class="text" @click="init(2)">农资分类</div>
            <div :class="{ slider: true, sliderType: sliderType == 2 }"></div>
          </div>
          <div class="top_cer">
            <div class="top_cer_t">
              <div
                class="pieList"
                v-for="(item, index) in pieData"
                :key="index"
              >
                <div class="text">
                  <div :class="'dian' + index" />
                  {{ item.name }}
                </div>
                <div class="money">
                  {{ item.value }} {{ sliderType == 1 ? "个" : "斤" }}
                </div>
              </div>
            </div>
            <div class="pie" ref="pie"></div>
          </div> -->

          <title2 name="专家概况" style="margin: 30px 0 24px"></title2>
          <div class="top_title">
            <div class="zj">专家分类</div>
            <div class="sl">数量</div>
            <div>专家占比</div>
          </div>
          <div class="zj_top">
            <div class="zj_left">
              <!-- <vue-seamless-scroll
                :data="totalArray"
                :class-option="defaultOption3"
              > -->
              <div class="zj_lefts" v-for="i in totalArray" :key="i.tags">
                <div class="text">{{ i.name }}</div>
                <div class="value">{{ i.value }}</div>
              </div>
              <!-- </vue-seamless-scroll> -->
            </div>

            <div ref="zj_tight" class="zj_tight"></div>
          </div>
          <div class="shell">
            <vue-seamless-scroll
              :data="townList"
              :class-option="defaultOption2"
            >
              <div class="vegetable">
                <div class="box" v-for="item in townList" :key="item.orgId">
                  <div class="name">
                    <span>{{ item.expertName }}</span
                    ><span class="span2">{{ item.tags }}</span>
                  </div>
                  <div class="heng"></div>
                  <div class="time">
                    <span class="span2">服务时间</span
                    ><span>{{ item.apr }}小时</span>
                  </div>
                </div>
              </div>
              ``
            </vue-seamless-scroll>
          </div>

          <title2 name="气象服务" style="margin: 30px 0 24px"></title2>
          <div class="shell2">
            <div class="vegetable2">
              <vue-seamless-scroll :data="qxList" :class-option="defaultOption">
                <div class="box" v-for="item in qxList" :key="item.orgId">
                  <div class="name">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="heng"></div>
                  <div class="time">
                    <div>
                      <span class="span2">月份</span
                      ><span>{{ item.month }}</span>
                    </div>
                    <div>
                      <span class="span2">服务类别</span
                      ><span>{{ item.serviceType }}</span>
                    </div>
                  </div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="title2" style="margin: 10px 0 35px 0">
            <div class="shu" />
            <div class="text">菜园农资概况</div>
          </div>
          <div class="unitWord">单位：斤</div>
          <ul class="garden-title">
            <li>
              <span v-for="item in titleArray" :key="item">{{ item }}</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body income">
            <vue-seamless-scroll :data="cyArray" :class-option="defaultOption">
              <li v-for="(item) in cyArray" :key="item">
                <span v-for="i in item" :key="i">{{ i }}</span>
              </li>
            </vue-seamless-scroll>
          </ul>
          <title2
            name="省农科院种子种苗赠送情况"
            style="margin: 30px 0 24px"
          ></title2>
          <ul class="garden-title">
            <li>
              <span v-for="item in dataObj.titleArr" :key="item">{{ item }}</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body income">
            <vue-seamless-scroll :data="dataObj.cyjsC2" :class-option="defaultOption">
              <li v-for="item in dataObj.cyjsC2" :key="item">
                <span v-for="i in item" :key="i">{{ i }}</span>
              </li>
            </vue-seamless-scroll>
          </ul>
          <!-- <div class="bar" ref="bar"></div> -->
          <title2 name="各镇、街道菜园主要种植品种及亩数"></title2>

          <ul class="garden-title">
            <li>
              <span>菜园</span>
              <span>阳光玫瑰葡萄</span>
              <span>榨菜</span>
              <span>地蒲</span>
              <span>萝卜</span>
              <span>辣椒</span>
              <span>玉米</span>
              <span>茄子</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body income">
            <vue-seamless-scroll
              :data="gardenList"
              :class-option="defaultOption"
            >
              <li
                v-for="item in gardenList"
                :key="item.orgId"
                @click="goGarden(item.orgId)"
              >
                <span>{{ item.town }}</span>
                <span>{{ item.salesIncome }}</span>
                <span>{{ item.distribute }}</span>
                <span>{{ item.distribute }}</span>
                <span>{{ item.distribute }}</span>
                <span>{{ item.distribute }}</span>
                <span>{{ item.distribute }}</span>
                <span>{{ item.distribute }}</span>
              </li>
            </vue-seamless-scroll>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataObj from "@/utils/data.json";
// import { splitToChunks } from "@/utils/index";
// const cellSize = [0, 0];
export default {
  name: "county",
  data() {
    return {
      leftData: {},
      dataObj,
      loading: false,
      timer: null,
      barData: {},
      pieData: [],
      townList: [],
      gardenList: [],
      titleData: {
        totalHouse: 0,
        distributeAmountYears: 0,
        averageIncome: 0,
      },
      sliderType: 1,
      cyArray: [],
      qxList: [],
      titleArray: [],
      ZjArray: [],
      ZjArrays: [],
      totalArray: [],
    };
  },
  computed: {
    optionBar() {
      return {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          // 让图表占满容器
          top: "30px",
          left: "30px",
          right: "0px",
          bottom: "30px",
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            // prettier-ignore

            data: this.barData.town,
            axisLine: {
              show: false,
              onZero: false,
              lineStyle: {
                color: "#ffffff",
              },
            },
            axisTick: {
              show: false, // x轴上的点
            },
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false,
              onZero: false,
              lineStyle: {
                color: "#ffffff",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0, 149, 255, .5)",
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "销售总额(万元)",
            type: "bar",
            data: this.barData.salesIncome,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(17, 210, 187, 1)", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(30, 174, 255, 0.6000)", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
        ],
      };
    },
    optionPie() {
      return {
        tooltip: {
          trigger: "item",
        },
        grid: {
          // 让图表占满容器
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },

        series: [
          {
            name: "已分配收入构成",
            type: "pie",
            radius: ["55%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              formatter: function (params) {
                return params.percent + "%";
              },
              // offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
              fontSize: 12,
              textStyle: {
                color: "#ffffff",
              },
            },
            labelLine: {
              show: false,
            },

            data: this.pieData,
          },
        ],
      };
    },
    optionZj() {
      return {};
    },
    defaultOption() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    defaultOption3() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    defaultOption2() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false,
      };
    },
  },
  mounted() {
    // myChart.showLoading({
    //   text: "加载中...",
    //   textColor: "#fff",
    //   maskColor: "transparent",
    // });
    // $getJson("330481").then((res) => {
    //   console.log(res);
    //   this.refresh(res.data.features)
    // });
    //this.init(1);
    this.gardenInfo();
    this.weatherInfo();
    this.specialist();
    this.refresh();

  },
  methods: {
    refresh() {
      this.barData = {};
      // this.pieData = [];
      let distribute = [];
      let salesIncome = [];
      let town = [];
      // 图形数据处理
      this.$api.bigdata.coRich("330481").then((res) => {
        res.data.townSalesList.map((item) => {
          distribute.push(item.distribute);
          salesIncome.push(item.salesIncome);
          town.push(item.town);
        });
        this.barData.town = Object.assign([], [].slice.call(town));
        this.barData.distribute = Object.assign([], [].slice.call(distribute));
        this.barData.salesIncome = Object.assign(
          [],
          [].slice.call(salesIncome)
        );
        // this.pieData = Object.assign([], [].slice.call(res.data.pieChartData));
        // console.log("111---", this.pieData);
        // this.townList = res.data.villageIncomeCompositionList;
        this.gardenList = res.data.townSalesList;
        // 标题数据
        this.titleData = {
          totalHouse: res.data.totalHouse,
          distributeAmountYears: res.data.distributeAmountYears,
          averageIncome: res.data.averageIncome,
        };
        // 柱状图
        const bar = this.$echarts.init(this.$refs.bar);
        bar.setOption(this.optionBar);

        this.loading = false;
      });
    },
    init(val) {
      this.sliderType = val;
      const pie = this.$echarts.init(this.$refs.pie);
      this.$api.bigdata.statisticsByConfig().then((res) => {
        if (val == 1) {
          this.pieData = res.data.agriculture_technology.map((item) => {
            return {
              name: item.name,
              value: item.sumValue,
            };
          });
        } else {
          this.pieData = res.data.agriculture_resource.map((item) => {
            return {
              name: item.name,
              value: item.sumValue,
            };
          });
        }
        this.$forceUpdate();
        pie.setOption(this.optionPie);
      });
    },
    gardenInfo() {
      this.$api.bigdata.gardenInfo().then((res) => {
        this.titleArray = res.data.length ? [...res.data[0]] : [];
        this.cyArray = res.data.splice(1);
        console.log(this.cyArray)
      });
      this.$api.bigdata.caiyuanTotalInfo().then((res) => {
        this.leftData = res.data
      })
    },
    //气象服务
    weatherInfo() {
      this.$api.bigdata.weatherInfo().then((res) => {
        this.qxList = res.data;
      });
    },
    specialist() {
      const zj_tight = this.$echarts.init(this.$refs.zj_tight);

      this.$api.bigdata.specialist().then((res) => {
        console.log(res);
        this.townList = res.data.specialistAnswer;
        this.ZjArray = res.data.specialistType.map((item) => {
          return {
            value: item.specialCount,
            name: item.tags,
            apr: item.apr,
          };
        });

        var maxData = this.ZjArray;
        // var xData = this.ZjArray;
        var mixData = [];
        let arr = res.data.specialistType.map((item) => {
          return item.specialCount;
        });
        let maxNum = Math.max(...arr);
        console.log(maxNum); // 900

        for (let i = 1; i < maxData.length; i++) {
          mixData.push(maxNum);
        }
        var tempMaxData = maxData.pop();
        // var tempXData = xData.pop();

        let optionZj = {
          tooltip: {
            formatter: (params) => {
              if (params.data.name) {
                let str =
                  "专家分类：" +
                  params.data.name +
                  "<br />数量：" +
                  params.data.value +
                  "<br />专家占比：" +
                  params.data.apr +
                  "%";
                return str;
              } else {
                return "";
              }
            },
          },
          legend: {},
          dataZoom: [
            //滚动缩放
            {
              type: "inside",
              yAxisIndex: 0,
              startValue: 0,
              endValue: 4,
              filterMode: "filter",
              height: 4,
              bottom: 10,
              disabled: true,
              handleSize: "300%",
              handleIcon:
                "image://data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB0PSIxNTg2NDg1MzkzMjI2IiBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHAtaWQ9IjQ3MzUiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PGRlZnM+PHN0eWxlIHR5cGU9InRleHQvY3NzIj48L3N0eWxlPjwvZGVmcz48cGF0aCBkPSJNNTEyIDBDMjI2Ljc0Mjg1NyAwIDAgMjI2Ljc0Mjg1NyAwIDUxMnMyMjYuNzQyODU3IDUxMiA1MTIgNTEyIDUxMi0yMjYuNzQyODU3IDUxMi01MTItMjI2Ljc0Mjg1Ny01MTItNTEyLTUxMnogbTAgODc3LjcxNDI4NmMtMjA0LjggMC0zNjUuNzE0Mjg2LTE2MC45MTQyODYtMzY1LjcxNDI4Ni0zNjUuNzE0Mjg2czE2MC45MTQyODYtMzY1LjcxNDI4NiAzNjUuNzE0Mjg2LTM2NS43MTQyODYgMzY1LjcxNDI4NiAxNjAuOTE0Mjg2IDM2NS43MTQyODYgMzY1LjcxNDI4Ni0xNjAuOTE0Mjg2IDM2NS43MTQyODYtMzY1LjcxNDI4NiAzNjUuNzE0Mjg2eiIgZmlsbD0iIzRBQkRDRiIgcC1pZD0iNDczNiI+PC9wYXRoPjxwYXRoIGQ9Ik01MTIgNTEybS0zNjUuNzE0Mjg2IDBhMzY1LjcxNDI4NiAzNjUuNzE0Mjg2IDAgMSAwIDczMS40Mjg1NzIgMCAzNjUuNzE0Mjg2IDM2NS43MTQyODYgMCAxIDAtNzMxLjQyODU3MiAwWiIgZmlsbD0iI0ZGRkZGRiIgcC1pZD0iNDczNyI+PC9wYXRoPjwvc3ZnPg==",
              handleStyle: {
                color: "#0E1557",
              },
              backgroundColor: "#1c3a75",
              fillerColor: "#295fcc",
              borderColor: "transparent",
              textStyle: {
                color: "transparent",
              },
              dataBackground: {
                areaStyle: {
                  opacity: 0,
                },
                lineStyle: {
                  opacity: 0,
                },
              },
            },
          ],
          grid: {
            top: "0%",
            left: "0%",
            right: "0%",
            bottom: "0%",
          },
          xAxis: {
            type: "value",
            show: false,
            boundaryGap: [0, 0.01],
          },
          yAxis: {
            type: "category",
            show: false,
            inverse: true,
          },

          series: [
            {
              type: "bar",
              barWidth: "14",
              data: maxData,
              zlevel: 1,
              itemStyle: {
                normal: {
                  barBorderRadius: 20,
                  //颜色设置
                  color: this.$echarts.graphic.LinearGradient(
                    0,
                    0,
                    1,
                    0,
                    [
                      {
                        offset: 0,
                        color: "#1DAEFF", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#2B68FF", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                },
              },
              label: {
                show: true,
                formatter: function (params) {
                  // console.log("params", params);
                  return params.data.apr + "%";
                },
                color: "white",
                fontFamily: "HarmonyOS Sans-Medium",
                fontSize: 14,
                position: "right",
              },
            },
            {
              name: "",
              type: "bar",
              barWidth: 14,
              barGap: "-100%",
              data: mixData,
              itemStyle: {
                normal: {
                  color: "rgba(28,93,180,0.6)",
                  barBorderRadius: [0, 30, 30, 0],
                },
              },
            },
          ],
        };

        var timer = null;
        timer = setInterval(() => {
          maxData.unshift(tempMaxData);
          tempMaxData = maxData.pop();
          this.totalArray = maxData;

          zj_tight.setOption(optionZj);
        }, 1500);

        zj_tight.on("mouseover", function () {
          clearInterval(timer);
          timer = null;
        });
        zj_tight.on("mouseout", function () {
          clearInterval(timer);
          timer = null;
          timer = setInterval(() => {
            maxData.unshift(tempMaxData);
            tempMaxData = maxData.pop();
            this.totalArray = maxData;

            zj_tight.setOption(optionZj);
          }, 1500);
        });
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_top {
  display: flex;
  overflow: hidden;
  .zj_left {
    width: 170px;
    height: 190px;
    margin-right: 20px;
    overflow: hidden;
    .zj_lefts {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      margin-top: 12px;
    }
    .text {
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      word-wrap: break-word;
    }
    .value {
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .zj_tight {
    width: 306px;
    height: 200px;
  }
}
.bar {
  width: 100%;
  height: 350px;
}
.top_cer {
  display: flex;
}
.pie {
  width: 180px;
  height: 180px;
}
.changePie {
  width: 238px;
  height: 48px;
  background: linear-gradient(180deg, #06409e 0%, #06409e 100%);
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  border: 1px solid rgba(0, 170, 255, 0.2);
  display: flex;
  position: relative;
  padding: 4px;
  .text {
    flex: 1;
    font-size: 18px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 1px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .slider {
    width: 115px;
    height: 38px;
    background: linear-gradient(90deg, #00c6ff 0%, #0072ff 100%);
    box-shadow: inset 0px 0px 2px 0px #ffffff;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    position: absolute;
    z-index: 0;
    left: 4px;
    transition: all 0.5s;
  }
  .sliderType {
    transform: translateX(114px);
    transition: all 0.5s;
  }
}
.top_cer_t {
  margin-top: 10px;
}
.pieList {
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
  width: 240px;
  margin-bottom: 5px;

  .text {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      opacity: 1;
      margin-right: 5px;
    }
    .dian0 {
      background: #5470c6;
    }
    .dian1 {
      background: #91cc75;
    }
    .dian2 {
      background: #fac858;
    }
    .dian3 {
      background: #ee6666;
    }
    .dian4 {
      background: #73c0de;
    }
  }
  .money {
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
}
.title2 {
  display: flex;
  align-items: center;
  .shu {
    width: 4px;
    height: 20px;
    background: #00aaff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #00aaff;
    margin-right: 8px;
  }
  .text {
    font-size: 18px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 1px;
  }
}
.shell {
  width: 480px;
  height: 94px;
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
}
.shellRight {
  width: 1318px;
}
.vegetable {
  width: 100%;
  height: 94px;
  display: flex;
  align-items: center;
  .box {
    width: 236px;
    height: 94px;
    background: url("../assets/images/菜园建设／专家／卡片bg@2x.png") no-repeat;
    background-size: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;

    .name {
      display: flex;
      align-items: flex-end;
      span {
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .span2 {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        margin-left: 10px;
      }
    }
    .time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .span2 {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
      }
    }
    .heng {
      width: 16px;
      height: 4px;
      background: #00aaff;
      opacity: 1;
      margin: 4px 0 10px;
    }
  }
}
.shell2 {
  width: 480px;
  height: 310px;
  overflow: hidden;
  border-radius: 8px 8px 8px 8px;
}
.vegetable2 {
  width: 100%;
  height: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .box {
    width: 480px;
    height: 94px;
    background: url("../assets/images/菜园建设／气象服务／卡片bg@2x.png")
      no-repeat;
    background-size: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    .name {
      display: flex;
      align-items: flex-end;
      span {
        font-size: 18px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .span2 {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        margin-left: 10px;
      }
    }
    .time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
      }
      span {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .span2 {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        margin-right: 10px;
      }
    }
    .heng {
      width: 16px;
      height: 4px;
      background: #00aaff;
      opacity: 1;
      margin: 4px 0 10px;
    }
  }
}
.main {
  .content {
    display: flex;
    padding: 24px 46px;
    .left {
      position: relative;
      width: 480px;
      // margin-right: 35px;
      // padding-right: 35px;
      .top-total {
        display: flex;
        .item {
          display: flex;
          align-items: center;
          width: 441px;
          height: 115px;
          background: url("../assets/images/card@2x.png");
          background-size: 100% 100%;
          font-size: 0;
          &.addbody {
            width: 441px;
          }
          & + .item {
            margin-left: 25px;
          }
          &:nth-child(2) {
            background-image: url("../assets/images/card@2x (1).png");
            .num,
            .unit {
              color: #00d5ee;
            }
          }
          &:nth-child(3) {
            background-image: url("../assets/images/card@2x (2).png");
            .num,
            .unit {
              color: #f2b800;
            }
          }
          .icon {
            width: 60px;
            margin: 0 0 0 30px;
            img {
              width: 100%;
            }
          }
          .body {
            padding: 0 20px;
            transform: translateX(170px);
            & + .body {
              border-left: 1px solid #eef8ff;
            }
          }
          .num {
            font-size: 32px;
            font-weight: 600;
            // font-style: italic;
            font-family: DIN Medium-Regular, DIN Medium;
            color: #00d387;
          }
          .unit {
            font-size: 16px;
            margin-left: 3px;
            color: #00d387;
          }
          .name {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .year-total {
        margin: 30px 0;
        width: 239px;
        height: 427px;
        background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/year.png);
        background-size: 100% 100%;
        .header {
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 24px;
        }
        .list {
          .round-rotate {
            margin: 10px 0;
          }
        }
      }
      .county-total {
        font-size: 18px;
        margin-top: 30px;
        border-top: 1px solid rgba(0, 149, 255, 0.5);
        border-left: 1px solid rgba(0, 149, 255, 0.5);
        table {
          width: 100%;
          tr {
            &.area {
              td {
                background: rgba(0, 63, 152, 1);
                text-shadow: 0 0 2px #007ffc;
              }
            }
            &.num {
              td {
                &:nth-child(odd) {
                  .line {
                    background-image: linear-gradient(
                      0deg,
                      rgba(247, 181, 0, 0) 0%,
                      rgba(247, 181, 0, 0.75) 100%
                    );
                    border-color: rgba(#f7b500, 0.7);
                  }
                }
              }
            }
            td {
              text-align: center;
              vertical-align: bottom;
              padding: 5px 0;
              line-height: 42px;
              border-right: 1px solid rgba(0, 149, 255, 0.5);
              border-bottom: 1px solid rgba(0, 149, 255, 0.5);
              background: rgba(0, 63, 152, 0.4);
              &.header {
                color: #00aaff;
              }
            }
          }
        }
        .bar {
          position: relative;
          height: 115px;
          width: 70%;
          margin: 0 auto;
          .text {
            position: absolute;
            width: 100%;
            bottom: 0;
          }
          .line {
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 50%;
            background-image: linear-gradient(
              0deg,
              rgba(0, 199, 255, 0) 0%,
              rgba(0, 149, 255, 0.75) 100%
            );
            border: 2px solid rgba(#00afff, 0.7);
            border-bottom: none;
            transition: height 0.3s;
          }
        }
      }
      .map {
        position: absolute;
        top: 80px;
        left: 250px;
        right: 0;
        bottom: 250px;
      }
      .light-level {
        position: absolute;
        left: 50%;
        transform: translateX(-25%);
        top: 540px;
        font-size: 0;
        text-align: center;
        li {
          margin: 0 8px;
          display: inline-block;
          cursor: pointer;
          width: 160px;
          height: 56px;
          line-height: 56px;
          background-size: 100% 100%;
          &:nth-child(1) {
            background-image: url(../assets/images/green_bg.png);
          }
          &:nth-child(2) {
            background-image: url(../assets/images/yellow_bg.png);
          }
          &:nth-child(3) {
            background-image: url(../assets/images/red_bg.png);
          }
          img {
            display: inline-block;
            width: 40px;
            vertical-align: top;
            margin-top: 7px;
          }
          span {
            display: inline-block;
            vertical-align: top;
            &.num {
              font-size: 18px;
              font-style: italic;
              margin: 0 2px 0 6px;
            }
            &.unit {
              font-size: 14px;
            }
          }
        }
      }
    }
    .right {
      width: 1318px;
      margin-left: 35px;
      ::v-deep .el-carousel__container {
        height: 234px;
        margin: 21px 0;
      }
      .plant-list {
        overflow: hidden;
        li {
          display: flex;
          padding: 0 16px;
          height: 54px;
          line-height: 54px;
          font-size: 18px;
          background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/plant.png);
          background-size: 100% 100%;
          & + li {
            margin-top: 6px;
          }
          .name {
            width: 130px;
            text-shadow: 0 0 2px #007ffc;
          }
          .num {
            width: 110px;
            color: #00aaff;
          }
          .bar {
            flex: 1;
            .line {
              margin-top: 13px;
              height: 30px;
              width: 50%;
              background-color: #007ffc;
            }
          }
        }
      }
      .garden-list {
        margin: 10px 0 0;
        &.garden-list-body {
          margin: 0;
          height: 220px;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        &.income {
          margin: 0 0 22px 0;
        }
        li {
          font-size: 0;
          background: url("../assets/images/菜园建设／农技、农资、种植概况／bg@2x.png")
            no-repeat;
          background-size: 100%;
          display: flex;
          margin-bottom: 10px;
          span {
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            letter-spacing: 1px;
            display: inline-block;
            flex: 1;
            font-size: 16px;
            padding-left: 20px;
            padding-top: 3px;
            line-height: 46px;
            color: #ffffff;
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
      .garden-title {
        margin: 10px 0 0;
        &.garden-list-body {
          margin: 0;
          height: 400px;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        &.income {
          margin: 0 0 22px 0;
        }
        li {
          font-size: 0;
          display: flex;
          span {
            letter-spacing: 1px;
            display: inline-block;

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            word-break: break-all;
            word-wrap: break-word;
            font-size: 16px;
            padding-left: 20px;
            line-height: 46px;
            flex: 1;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
    }
  }
}
.top_title {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  margin-bottom: 5px;
  .zj {
    width: 64px;
    margin-right: 80px;
  }
  .sl {
    margin-right: 20px;
  }
}

.liBox {
  height: 160px;
  background: linear-gradient(90deg, rgba(0,115,201,0.3) 0%, rgba(0,115,201,0.3) 0%, rgba(0,63,152,0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid;
  border-image: linear-gradient(270deg, rgba(0, 199, 255, 0), rgba(0, 149, 255, 1)) 1 1;
  padding: 40px 20px;
  .liCon {
    display: flex;
    margin-bottom: 30px;
    .c {
      width: 50%;
      font-size: 20px;
      span {
        padding-left: 5px;
        font-weight: bold;
      }
    }
  }
}
.unitWord {
  font-size: 14px;
  text-align: right;
}
</style>
